import PropTypes from 'prop-types'
import React from 'react'
import styled, { withTheme } from 'styled-components'
import is from 'styled-is'

const context = require.context('../assets/icons', false, /\.svg$/)
const icons = context.keys().reduce((map, key) => {
  const name = key.replace(/.*\.\/(.*)\.svg$/, '$1')
  map[name] = context(key)
  return map
}, {})

const Container = styled.span`
  width: 32px;
  height: 32px;

  svg {
    width: 32px;
    height: 32px;
  }

  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.color};

  ${is('bordered')`
    border-style: solid;
    border-width: 1px;
    border-color: ${(props) => props.border || props.theme.colors.brandPrimary};
    border-radius: 50%;
  `};

  ${is('background')`
    background-color: ${(props) => props.background};
  `};

  ${is('onClick')`
    cursor: pointer;
  `};
`

const Svg = styled.svg`
  stroke: currentColor;
`

const Icon = ({
  name,
  onClick,
  theme,
  color,
  bordered,
  border,
  background,
  ...props
}) => {
  const svg = icons[name]
  return (
    <Container
      viewBox={svg.viewBox}
      onClick={onClick}
      color={color}
      bordered={bordered}
      background={background}
      border={border}
      {...props}
    >
      <Svg viewBox={svg.viewBox}>
        <use xlinkHref={`${svg.symbol}`} />
      </Svg>
    </Container>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  background: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
}

export const names = Object.keys(icons)
export default withTheme(Icon)
